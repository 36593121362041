import * as React from 'react';
import {SiteButton, SiteButtonKind} from './SiteButton';

export interface ScheduleDemoButtonProps
{
    kind?:SiteButtonKind;
}

export class ScheduleDemoButton extends React.Component<ScheduleDemoButtonProps, any>
{
    render()
    {
        const {kind=SiteButtonKind.Dark} = this.props;

        return <SiteButton kind={kind} text={'Get In Touch'} link={'mailto:sales@innovativesoftwaresolutions.net'}/>
    }
}