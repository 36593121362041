import * as React from 'react';
import {PageBackground} from '../components/PageBackground';
import {PageHeader} from '../components/PageHeader';
import {VSpacer} from '../components/VSpacer';
import {Images} from '../images/Images';
import {HAlign, HAligner} from '../components/HAligner';
import {Text, TextSize, TextWeight} from '../components/Text';
import {PageFooter} from '../components/PageFooter';
import {PictureFrame} from '../components/PictureFrame';
import {SiteButton, SiteButtonKind} from '../components/SiteButton';
import {HSpacer} from '../components/HSpacer';
import {ScheduleDemoButton} from '../components/ScheduleDemoButton';

// declare var Calendly:any;

export class MainPage extends React.Component<any, any>
{
    render()
    {
        return  <PageBackground>
                    <PageHeader/>

                    <VSpacer size={15}/>
                    <div className={'container'} style={{color:'black', border:'1px solid transparent'}}>
                        <div className={'row'}>
                            <div className={'col-sm-12'}>
                                <VSpacer size={15}/>
                                <HAligner align={HAlign.Center}>
                                    <Text isHighlight={false} size={TextSize.h1} weight={TextWeight.w400}>
                                    </Text>
                                    <Text isHighlight={false} size={TextSize.h1} weight={TextWeight.w400}>
                                        Technical Engineering, Redefined
                                    </Text>
                                </HAligner>
                            </div>

                        </div>
                    </div>

                    <VSpacer size={35}/>
                    <div className={'container'} style={{color:'black', border:'1px solid transparent'}}>
                        <div className={'row'} style={{backgroundColor:'#FAFAFA', border:'1px solid #EFEFEF', boxShadow:'2px 2px 4px 0 rgba(202,202,202,0.50)'}}>
                            <div className={'col-lg-4'} style={{padding:'30px 20px 30px 30px'}}>
                                <VSpacer size={15}/>
                                <Text isHighlight={false} size={TextSize.h2} weight={TextWeight.w400}>
                                    Outsourced Technical Engineering Services
                                </Text>

                                <VSpacer size={15}/>
                                <Text isHighlight={false} size={TextSize.h3} weight={TextWeight.w300}>

                                    Partner with us for better engineered solutions, at a fraction of the cost.
                                    {/*to build your software faster, better, and at an effective cost.*/}
                                    <br/><br/>
                                    Leverage our engineers to increase software development speed, software testing, and deployment.
                                    <br/><br/>

                                    Cost savings and quality are the main factors why our customers trust us with a wide breadth of technical services.

                                    <br/><br/>
                                    Complete your business objectives at breakneck speed. Get started today.

                                    <br/><br/>

                                </Text>


                                <div style={{display:'flex', justifyContent:'flex-start', border: '1px solid transparent'}}>
                                    <SiteButton text={'Our Services'} link={'/services'}/>
                                    <HSpacer size={10}/>

                                    <ScheduleDemoButton/>
                                </div>


                            </div>
                            <div className={'col-lg-8'} style={{padding:'40px 40px'}}>
                                <PictureFrame style={{borderColor:'rgba(0,0,0,0.05)'}}>
                                    {/*<img width={'100%'} src={Images.WorkspaceTopologyView}/>*/}
                                    <img width={'100%'} src={Images.Office1}/>
                                </PictureFrame>
                            </div>
                        </div>
                    </div>

                    <div className={'container'} style={{color:'black', border:'1px solid transparent',
                                                        marginTop:'50px', marginBottom:'50px'}}>
                        <div className={'row'}>
                            <div className={'col-sm-12'}>
                                <Text isHighlight={false} size={TextSize.h2} weight={TextWeight.w400}>
                                    Why work with us?
                                </Text>
                                <VSpacer size={13}/>

                                <Text isHighlight={false} size={TextSize.h3} weight={TextWeight.w300} lineHeight={1.2}>

                                    Trusting any outsourced technical services partner is tough. Very few are reliable and able to deliver on time and within budget.

                                    Our approach is that transparency, hard work, and a desire to work hard underpins everything that we do.

                                    We are genuinely greatful for all the amazing technical work we get to do with our customers, and hope to join with you on this journey.

                                </Text>

                            </div>
                        </div>
                    </div>

                    <div className={'container'} style={{color:'black', border:'1px solid transparent',
                        marginTop:'50px', marginBottom:'50px'}}>
                        <div className={'row'}>
                            <div className={'col-sm-12'}>
                                <Text isHighlight={false} size={TextSize.h2} weight={TextWeight.w400}>
                                    What services do we offer?
                                </Text>
                                <VSpacer size={13}/>

                                <Text isHighlight={false} size={TextSize.h3} weight={TextWeight.w300} lineHeight={1.2}>

                                    Our outsourced technical engineering services comprise of software development, software testing, and support.

                                    We'll work with you to understand your exact mechanisms and ways in which your organization works, and then fit within that so as to minimize disruption to existing processes. For our technical engineering services, here's what we offer:
                                    <br/><br/>

                                    <Text isHighlight={false} size={TextSize.h4} weight={TextWeight.w500} lineHeight={1.3}>
                                    <ul>
                                        <li>
                                            <a href={'/services'}>→ Technical Engineering</a>
                                        </li>
                                        <li>
                                            <a href={'/services'}>→ Software Architecture & Development</a>
                                        </li>
                                        <li>
                                            <a href={'/services'}>→ Software Testing & Deployment</a>
                                        </li>
                                        <li>
                                            <a href={'/services'}>→ Software Analysis & Tuning</a>
                                        </li>
                                    </ul>
                                    </Text>

                                </Text>

                            </div>
                        </div>
                    </div>

                    <PageFooter />

                </PageBackground>;
    }
}

export default () => <MainPage/>;






